import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'

export default () => (
  <Layout>
    <Helmet>
      <title>FAQs | Brisbane & Sunshine Coast’s Trusted Builders</title>
      <meta
        name="description"
        content="Find out everything you need to know about building your dream patio, deck, carport or room enclosure from Just Patios. Got a question that isn’t listed here? Just call us & we’re all ears. We also offer a free design & quote!"
      />
    </Helmet>
    <Header>
      <h1>Frequently asked questions</h1>
    </Header>
    <FAQs />
    <CTA
      title="Looking for a builder?"
      subtitle="We’re here to help you."
      button="Contact Us"
    />
  </Layout>
)
